<template>
  <div v-show="isVisible" class="inline-block">
    <slot />
  </div>
</template>

<script lang="ts" setup>
const registerItem = inject('registerItem');
const unregisterItem = inject('unregisterItem');
const totalVisible = inject('totalVisible');

const index = ref(0);

const isVisible = computed(() => index.value < totalVisible.value);

onMounted(() => {
  index.value = registerItem();
});

onUnmounted(() => {
  unregisterItem();
});
</script>