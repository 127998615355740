<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path d="M7 6H21M7 12H21M7 18H21" stroke="black" stroke-width="2" />
    <path
      d="M2 4.5H3.5V8M2 10.5H4.5V11L2.5 13V13.5H5M2 16.5H4.5V18M2 19.5H4.5V18M4.5 18H3"
      stroke="black"
    />
  </svg>
</template>
