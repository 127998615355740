<template>
  <div>
    <div class="label-sm mb-1 text-neutral-400">
      <slot name="heading" />
    </div>

    <div class="-mb-1 flex flex-wrap">
      <slot />
    </div>
    <div
      v-if="showMoreButton || $slots.footer"
      :class="
        props.buttonAlign === 'center'
          ? 'text-center'
          : props.buttonAlign === 'right'
            ? 'text-right'
            : 'text-left'
      "
    >
      <button
        v-if="showMoreButton"
        class="font-display text-sm font-bold text-blue-700"
        @click="showAll"
      >
        + {{ t("more", hiddenItemsCount) }}
      </button>
      <div
        v-if="$slots.footer"
        class="font-display text-sm font-bold text-blue-700"
        :class="{ 'mt-2': showMoreButton }"
      >
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    defaultShow?: number;
    buttonAlign?: "left" | "center" | "right";
  }>(),
  {
    defaultShow: 5,
    buttonAlign: "left",
  },
);

const totalVisible = ref(props.defaultShow);
const itemsCount = ref(0);

const registerItem = () => {
  const index = itemsCount.value;
  itemsCount.value++;
  return index;
};

const unregisterItem = () => {
  itemsCount.value--;
};

provide("registerItem", registerItem);
provide("unregisterItem", unregisterItem);
provide("totalVisible", totalVisible);

const showAll = () => {
  totalVisible.value = itemsCount.value;
};

const hiddenItemsCount = computed(() => {
  return itemsCount.value - totalVisible.value;
});

const showMoreButton = computed(() => {
  return hiddenItemsCount.value > 0;
});

const { t } = useI18n({
  useScope: "local",
  messages: {
    cs: {
      more: "0 dalších | 1 další | {count} další | {count} dalších",
    },
    en: {
      more: "0 more | 1 more | {count} more | {count} more",
    },
  },
});
</script>
