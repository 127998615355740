<template>
  <div class="grid grid-cols-12 lg:mt-6">
    <main class="relative col-span-12 md:col-span-7 lg:col-span-8">
      <slot name="content" />
    </main>

    <aside
      class="relative col-span-12 mt-6 md:col-span-5 md:mt-0 md:pl-6 lg:col-span-4 xl:pl-12"
    >
      <div
        class="h-full border-neutral-200 md:sticky md:top-16 md:border-l md:pl-6 xl:pl-12"
      >
        <slot name="sidebar" />
      </div>
    </aside>

    <div
      v-if="$slots.bottom"
      class="col-span-12 divide-y divide-neutral-200 border-t md:mt-12 md:space-y-6 md:pt-12"
    >
      <slot name="bottom" />
    </div>
  </div>
</template>
