<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M10 6H14M17.5 6H14M14 6L10 19M10 19H6.5M10 19H14"
      stroke="black"
      stroke-width="2"
    />
  </svg>
</template>
