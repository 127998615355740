<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M10 5H11C11 4.59554 10.7564 4.2309 10.3827 4.07612C10.009 3.92134 9.57889 4.0069 9.29289 4.29289L10 5ZM10 9H9V10H10V9ZM21 20L20.2 20.6C20.4766 20.9688 20.9695 21.1007 21.3934 20.9194C21.8173 20.738 22.0622 20.2904 21.9864 19.8356L21 20ZM10 15V14H9V15H10ZM10 19L9.29289 19.7071C9.57889 19.9931 10.009 20.0787 10.3827 19.9239C10.7564 19.7691 11 19.4045 11 19H10ZM3 12L2.29289 11.2929L1.58579 12L2.29289 12.7071L3 12ZM9 5V9H11V5H9ZM10 10C13.4777 10 18.8883 13.4128 20.0136 20.1644L21.9864 19.8356C20.7117 12.1872 14.5223 8 10 8V10ZM21.8 19.4C21.0289 18.3719 19.9332 16.9925 18.0723 15.8897C16.2064 14.784 13.6492 14 10 14V16C13.3508 16 15.5436 16.716 17.0527 17.6103C18.5668 18.5075 19.4711 19.6281 20.2 20.6L21.8 19.4ZM9 15V19H11V15H9ZM10.7071 18.2929L3.70711 11.2929L2.29289 12.7071L9.29289 19.7071L10.7071 18.2929ZM3.70711 12.7071L10.7071 5.70711L9.29289 4.29289L2.29289 11.2929L3.70711 12.7071Z"
      fill="black"
    />
  </svg>
</template>
