<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M7 12.5V19H13.5625C15.461 19 17 17.5449 17 15.75C17 13.9551 15.461 12.5 13.5625 12.5H7ZM7 12.5H12.875C14.7735 12.5 16.3125 11.0449 16.3125 9.25C16.3125 7.45507 14.7735 6 12.875 6H7V12.5Z"
      stroke="black"
      stroke-width="2"
    />
  </svg>
</template>
