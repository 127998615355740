<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M14 5H13C13 4.59554 13.2436 4.2309 13.6173 4.07612C13.991 3.92134 14.4211 4.0069 14.7071 4.29289L14 5ZM14 9H15V10H14V9ZM3 20L3.8 20.6C3.52338 20.9688 3.03046 21.1007 2.6066 20.9194C2.18274 20.738 1.93781 20.2904 2.01361 19.8356L3 20ZM14 15V14H15V15H14ZM14 19L14.7071 19.7071C14.4211 19.9931 13.991 20.0787 13.6173 19.9239C13.2436 19.7691 13 19.4045 13 19H14ZM21 12L21.7071 11.2929L22.4142 12L21.7071 12.7071L21 12ZM15 5V9H13V5H15ZM14 10C10.5223 10 5.11166 13.4128 3.98639 20.1644L2.01361 19.8356C3.28834 12.1872 9.47771 8 14 8V10ZM2.2 19.4C2.97108 18.3719 4.06676 16.9925 5.9277 15.8897C7.79356 14.784 10.3508 14 14 14V16C10.6492 16 8.45644 16.716 6.9473 17.6103C5.43324 18.5075 4.52892 19.6281 3.8 20.6L2.2 19.4ZM15 15V19H13V15H15ZM13.2929 18.2929L20.2929 11.2929L21.7071 12.7071L14.7071 19.7071L13.2929 18.2929ZM20.2929 12.7071L13.2929 5.70711L14.7071 4.29289L21.7071 11.2929L20.2929 12.7071Z"
      fill="black"
    />
  </svg>
</template>
