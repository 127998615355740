<template>
  <div>
    <div class="sm:hidden">
      <div
        ref="wrapper"
        class="relative overflow-y-hidden transition-all duration-[250ms] ease-in-out"
        :style="{
          maxHeight: isExpanded
            ? `${contentHeight}px`
            : `${props.maxMobileHeight}px`,
        }"
      >
        <div class="output-content">
          <slot />
        </div>

        <div
          v-if="isOverflow"
          :class="[
            isExpanded ? 'opacity-0' : 'opacity-100',
            'absolute inset-x-0 bottom-0 h-24 bg-gradient-to-t from-white transition-opacity',
          ]"
        />
      </div>
      <button
        v-if="isOverflow"
        :class="[
          'mx-auto mt-2 flex flex-row items-center justify-center px-6 py-3 text-base font-bold text-blue-700',
        ]"
        @click="isExpanded = !isExpanded"
      >
        {{ isExpanded ? t("readLess") : t("readMore") }}
        <ChevronUpIcon
          :class="[
            isExpanded ? 'rotate-0' : 'rotate-180',
            'ml-2 h-5 w-5 transform-gpu stroke-blue-700 stroke-2 transition-transform',
          ]"
        />
      </button>
    </div>

    <div class="output-content hidden sm:block">
      <slot />
    </div>
  </div>
</template>
<script setup lang="ts">
import { ChevronUpIcon } from "@heroicons/vue/24/outline";

const wrapper = ref<HTMLDivElement>();
const isExpanded = ref(false);

const props = withDefaults(
  defineProps<{
    maxMobileHeight?: number;
  }>(),
  {
    maxMobileHeight: 420,
  },
);

const contentHeight = ref(0);
useResizeObserver(wrapper, (entries) => {
  const entry = entries[0];
  const { scrollHeight } = entry.target;
  contentHeight.value = scrollHeight;
});

const isOverflow = computed(() => {
  return props.maxMobileHeight < contentHeight.value;
});

const { t } = useI18n({
  useScope: "local",
  messages: {
    cs: {
      readMore: "Číst dále",
      readLess: "Číst méně",
    },
    en: {
      readMore: "Read more",
      readLess: "Read less",
    },
  },
});
</script>
